<template>
  <LoaderSection v-show="isLoading" />
  <NavigationSection />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <h1 class="fw-bold h3 text-dark"><font-awesome-icon :icon="['fas', 'cog']" class="me-3" />Settings</h1>

              <div class="row">

                <div class="col-12">

                  <div class="nav-tabs-container position-relative" ref="tabContainer">
                    <ul class="nav nav-tabs flex-nowrap mt-4" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active bg-white transaction-nav" id="account-tab" data-bs-toggle="tab" data-bs-target="#account" type="button" role="tab" aria-controls="account" aria-selected="true" ref="accountTab"><font-awesome-icon :icon="['far', 'user']" class="me-2" />Account details</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link bg-white transaction-nav" id="bank-tab" data-bs-toggle="tab" data-bs-target="#bank" type="button" role="tab" aria-controls="bank" aria-selected="true" ref="bankTab"><font-awesome-icon :icon="['far', 'credit-card']" />Bank account</button>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content" v-if="!onboardingStatus || onboardingStatus < 2 && 1==2">

                    <div class="alert alert-warning mt-3">
                      You need to complete your <router-link :to="{ name: 'publicGetStarted' }" class="text-primary link-underline">Seller Onboarding</router-link>, before you can manage your account settings.
                    </div>

                  </div>
                  <div class="tab-content" v-else>

                    <!-- Mangopay error details -->
                    <div class="alert alert-danger mt-3" role="alert" v-if="mangopayOnboardingError">
                      {{ mangopayOnboardingError }}
                    </div>
                    <!-- ./Mangopay error details -->

                    <!-- Account settings -->
                    <div class="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                      <div class="card border border-top-0 rounded-0 pt-2 bg-white">
                        <div class="card-body bg-white" v-if="formPrefilledProfileValues">
                          <div class="row">
                            <div class="col-12">

                              <div>

                                <form action="" method="get" class="h-100" id="profileDetailsForm">
                                  <div class="row">

                                    <div class="col-12 col-lg-6">
                                      <div class="input-group mt-3">

                                        <div class="form-floating">
                                          <select class="form-select disabled" name="onboardingType" id="onboardingType" required disabled="disabled">

                                            <option selected></option>
                                            <option value="LEGAL_USER" :selected="formPrefilledProfileValues.onboardingType === 'LEGAL_USER'">Yes</option>
                                            <option value="NATURAL_USER" :selected="formPrefilledProfileValues.onboardingType === 'NATURAL_USER'">No</option>

                                          </select>
                                          <label for="onboardingType" class="form-label">I represent a company</label>
                                        </div>
                                        <span data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-original-title="Efty Pay serves both consumers and businesses. Therefore, there are two types of users: <ul><li>Natural users, for individuals (i.e., natural persons) over the age of 18.</li><li>Legal users, for entities like companies, organizations, and sole proprietors (i.e., legal persons).</li></ul>" data-bs-html="true" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                                      </div>
                                    </div>

                                    <div class="col-12 col-lg-6">

                                      <div class="input-group mt-3" v-if="isLegalEntity">

                                        <div class="form-floating">
                                          <select class="form-select disabled" id="legalUserType" name="legalUserType" required disabled="disabled">
                                            <option selected></option>
                                            <option value="BUSINESS" :selected="formPrefilledProfileValues.legalUserType === 'BUSINESS'">Business</option>
                                            <option value="ORGANIZATION" :selected="formPrefilledProfileValues.legalUserType === 'ORGANIZATION'">Organisation</option>
                                            <option value="SOLETRADER" :selected="formPrefilledProfileValues.legalUserType === 'SOLETRADER'">Sole Proprietor</option>
                                          </select>
                                          <label for="legalUserType" class="form-label">Company type</label>
                                        </div>
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="Efty Pay serves three types of companies:<ul><li>Business, like a company or corporation</li><li>Organization, for non-profits and similar</li><li>Sole Proprietors</li></ul>" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr class="mt-4">

                                  <div class="row">

                                    <div class="col-12 col-lg-6">

                                      <div class="container-fluid" v-if="!isLegalEntity">
                                        <div class="row">

                                          <div class="col-6 px-0">
                                            <div class="form-floating mt-3">
                                              <input type="text" class="form-control" id="firstName" name="firstName" placeholder="" :value="formPrefilledProfileValues.firstName" required>
                                              <label for="firstName" class="form-label">First Name</label>
                                            </div>
                                          </div>

                                          <div class="col-6 px-0 ps-3">
                                            <div class="form-floating mt-3">
                                              <input type="text" class="form-control" id="lastName" name="lastName" placeholder="" :value="formPrefilledProfileValues.lastName" required>
                                              <label for="lastName" class="form-label">Last Name</label>
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                      <div class="input-group mt-3" v-if="!isLegalEntity">
                                        <div class="form-floating">
                                          <input type="email" disabled="disabled" class="form-control" id="email" name="email" placeholder="" :value="formPrefilledProfileValues.email">
                                          <label for="email" class="form-label">E-mail address</label>
                                        </div>
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="You can not change this e-mail address because this is the e-mail address we use to link your Efty Investor account to Efty Pay" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                                      </div>

                                      <div class="form-floating mt-3" v-if="isLegalEntity">
                                        <input type="text" class="form-control" id="companyNumber" name="companyNumber" placeholder="" required :value="formPrefilledProfileValues.companyNumber">
                                        <label for="companyNumber" class="form-label">Company registration number</label>
                                      </div>

                                      <div class="form-floating mt-3" v-if="isLegalEntity">
                                        <input type="text" class="form-control" id="companyVatNumber" name="companyVatNumber" placeholder="" :value="formPrefilledProfileValues.companyVatNumber">
                                        <label for="companyVatNumber" class="form-label">Company VAT number</label>
                                      </div>

                                      <div class="form-floating mt-3" v-if="isLegalEntity">
                                        <input type="text" class="form-control" id="registeredName" name="registeredName" placeholder="" required :value="formPrefilledProfileValues.registeredName">
                                        <label for="registeredName" class="form-label">Company name</label>
                                      </div>

                                      <div class="input-group mt-3" v-if="isLegalEntity">
                                        <div class="form-floating">
                                          <input type="email" disabled="disabled" class="form-control" id="email" name="email" placeholder="" :value="formPrefilledProfileValues.email">
                                          <label for="email" class="form-label">Company e-mail address</label>
                                        </div>
                                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="You can not change this e-mail address because this is the e-mail address we use to link your Efty Investor account to Efty Pay" class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                                      </div>
                                    </div>

                                    <div class="col-12 col-lg-6">

                                      <!-- Natural person info -->
                                      <div v-if="!isLegalEntity">

                                        <div class="form-floating mt-3">
                                          <input type="date" class="form-control" name="dateOfBirth" id="dateOfBirth" placeholder="" :value="formPrefilledProfileValues.dateOfBirth ? dayjs(formPrefilledProfileValues.dateOfBirth).format('YYYY-MM-DD') : ''" required>
                                          <label for="dateOfBirth" class="form-label">Date of Birth</label>
                                        </div>

                                        <div class="form-floating mt-3">
                                          <select class="form-select" id="countryOfResidence" name="countryOfResidence" required>
                                            <option selected></option>
                                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledProfileValues.countryOfResidence" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                          </select>
                                          <label for="countryOfResidence" class="form-label">Country of residence</label>
                                        </div>

                                        <div class="form-floating mt-3">
                                          <select class="form-select" id="nationality" name="nationality" required>
                                            <option selected></option>
                                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledProfileValues.nationality" :disabled="isoCountry.blocked">{{ isoCountry.name }}</option>
                                          </select>
                                          <label for="nationality" class="form-label">Nationality</label>
                                        </div>

                                      </div>
                                      <!-- ./Natural person info -->

                                      <!-- Company address -->
                                      <div v-if="isLegalEntity">

                                        <div class="form-floating mt-3">
                                          <input type="text" class="form-control" id="addressLine1" name="addressLine1" placeholder="" :value="formPrefilledProfileValues.addressLine1" required>
                                          <label for="addressLine1" class="form-label">Company Address</label>
                                        </div>

                                        <div class="container-fluid">
                                          <div class="row">

                                            <div class="col-6 px-0">
                                              <div class="form-floating mt-3">
                                                <input type="text" class="form-control" id="postalCode" name="postalCode" placeholder="" :value="formPrefilledProfileValues.postalCode" required>
                                                <label for="postalCode" class="form-label">Postal code</label>
                                              </div>
                                            </div>

                                            <div class="col-6 px-0 ps-3">
                                              <div class="form-floating mt-3">
                                                <input type="text" class="form-control" id="city" name="city" placeholder="" :value="formPrefilledProfileValues.city" required>
                                                <label for="city" class="form-label">City</label>
                                              </div>
                                            </div>

                                          </div>
                                        </div>

                                        <div class="container-fluid">
                                          <div class="row">

                                            <div class="col-6 px-0">
                                              <div class="form-floating mt-3">
                                                <input type="text" class="form-control" id="regionOrCounty" name="regionOrCounty" placeholder="" :value="formPrefilledProfileValues.regionOrCounty">
                                                <label for="regionOrCounty" class="form-label">Region</label>
                                              </div>
                                            </div>

                                            <div class="col-6 px-0 ps-3">
                                              <div class="form-floating mt-3">
                                                <select class="form-select" id="country" name="country" required>
                                                  <option selected></option>
                                                  <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledProfileValues.country" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                                </select>
                                                <label for="country" class="form-label">Country</label>
                                              </div>
                                            </div>

                                          </div>
                                        </div>

                                      </div>
                                      <!-- ./Company address -->

                                    </div>

                                  </div>

                                  <!-- Legal represetative -->
                                  <div v-if="isLegalEntity">
                                    <div class="row">
                                      <div class="col-12">

                                        <hr class="mt-4">
                                        <h3 class="fw-bold mt-4 h4">Legal representative of your company</h3>
                                        <p class="">Please provide us with details of the legal representative of your company. This is the person that will be actively using the Efty platform and acts as a legal representative of your company.</p>
                                      </div>
                                    </div>
                                    <div class="row">

                                      <div class="col-12 col-lg-6">

                                        <div class="container-fluid">

                                          <div class="row">

                                            <div class="col-6 px-0">
                                              <div class="form-floating mt-3">
                                                <input type="text" class="form-control" id="legalRepresentativeFirstName" name="legalRepresentativeFirstName" placeholder="" :value="formPrefilledProfileValues.legalRepresentativeFirstName" required>
                                                <label for="legalRepresentativeFirstName" class="form-label">First Name</label>
                                              </div>
                                            </div>

                                            <div class="col-6 px-0 ps-3">
                                              <div class="form-floating mt-3">
                                                <input type="text" class="form-control" id="legalRepresentativeLastName" name="legalRepresentativeLastName" placeholder="" :value="formPrefilledProfileValues.legalRepresentativeLastName" required>
                                                <label for="legalRepresentativeLastName" class="form-label">Last Name</label>
                                              </div>
                                            </div>

                                          </div>

                                        </div>

                                        <div class="form-floating mt-3">
                                          <input type="legalRepresentativeEmail" class="form-control" id="legalRepresentativeEmail" name="legalRepresentativeEmail" placeholder="" :value="formPrefilledProfileValues.legalRepresentativeEmail" required disabled="disabled">
                                          <label for="legalRepresentativeEmail" class="form-label">E-mail address</label>
                                        </div>

                                        <div class="form-floating mt-3">
                                          <input type="date" class="form-control" name="legalRepresentativeDateOfBirth" id="legalRepresentativeDateOfBirth" placeholder="" :value="formPrefilledProfileValues.legalRepresentativeDateOfBirth ? dayjs(formPrefilledProfileValues.legalRepresentativeDateOfBirth).format('YYYY-MM-DD') : ''" required>
                                          <label for="legalRepresentativeDateOfBirth" class="form-label">Date of Birth</label>
                                        </div>

                                      </div>
                                      <div class="col-12 col-lg-6">

                                        <div class="form-floating mt-3">
                                          <select class="form-select" id="legalRepresentativeCountryOfResidence" name="legalRepresentativeCountryOfResidence" required>
                                            <option selected></option>
                                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledProfileValues.legalRepresentativeCountryOfResidence" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                          </select>
                                          <label for="legalRepresentativeCountryOfResidence" class="form-label">Country of residence</label>
                                        </div>

                                        <div class="form-floating mt-3">
                                          <select class="form-select" id="legalRepresentativeNationality" name="legalRepresentativeNationality" required>
                                            <option selected></option>
                                            <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="isoCountry.code === formPrefilledProfileValues.legalRepresentativeNationality" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                          </select>
                                          <label for="legalRepresentativeNationality" class="form-label">Nationality</label>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                  <!-- ./Legal represetative -->

                                  <button class="btn btn-lg btn-success w-100 mt-3" role="button" type="button" @click="updateProfile()">Save</button>

                                </form>

                              </div>
                              <!-- ./Form with basic details -->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ./Account settings -->


                    <!-- Bank account settings -->
                    <div class="tab-pane fade show" id="bank" role="tabpanel" aria-labelledby="bank-tab">
                      <div class="card border border-top-0 rounded-0 pt-2 bg-white">
                        <div class="card-body bg-white" v-if="formPrefilledBankAccountValues">
                          <div class="row">
                            <div class="col-12">

                              <form action="" method="get" class="h-100" id="bankAccountForm">
                                <div class="row">

                                  <div class="col-12 col-lg-6">
                                    <div class="input-group mt-3">

                                      <div class="form-floating">

                                        <select class="form-select" name="bankAccountType" id="bankAccountType" @change="onBankAccountChange($event)" required>

                                          <option value="" selected></option>
                                          <option value="1" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankAccountType == 'IBAN' : ''">IBAN</option>
                                          <option value="2" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankAccountType == 'US' : ''">US</option>
                                          <option value="3" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankAccountType == 'CA' : ''">CA (Canada)</option>
                                          <option value="4" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankAccountType == 'GB' : ''">GB (UK)</option>
                                          <option value="5" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankAccountType == 'OTHER' : ''">Other</option>

                                        </select>
                                        <label for="bankAccountType" class="form-label">Bank account type</label>
                                      </div>
                                      <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-original-title="Your banking details are required in order to process payouts to your bank account. We support IBAN (Europe), US, Canadian and UK bank accounts. If you have another type of bank account, please fill out all details." class="input-group-text"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                                    </div>
                                  </div>

                                  <div class="col-12 col-lg-6">

                                    <div class="mt-3" v-if="isIbanBankAccount">

                                      <div class="form-floating">
                                        <input type="text" class="form-control" id="bankAccountIban" name="bankAccountIban" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.iban : ''" required>
                                        <label for="bankAccountIban" class="form-label">IBAN</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountBic" name="bankAccountBic" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bic : ''" required>
                                        <label for="bankAccountBic" class="form-label">BIC</label>
                                      </div>

                                    </div>

                                    <div class="mt-3" v-if="isUsBankAccount">

                                      <div class="form-floating">
                                        <input type="number" class="form-control" id="bankAccountNumber" name="bankAccountNumber" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.accountNumber : ''" required>
                                        <label for="bankAccountNumber" class="form-label">Account Number</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="number" class="form-control" id="bankAccountAba" name="bankAccountAba" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.aba : ''" required>
                                        <label for="bankAccountAba" class="form-label">ABA</label>
                                      </div>

                                      <div class="form-floating mt-3">

                                        <select class="form-select" name="bankAccountDepositType" id="bankAccountDepositType" required>

                                          <option selected></option>
                                          <option value="CHECKING" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.depositType == 'CHECKING' : ''">CHECKING</option>
                                          <option value="SAVINGS" :selected="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.depositType == 'SAVINGS' : ''">SAVINGS</option>

                                        </select>
                                        <label for="bankAccountDepositType" class="form-label">Deposit type</label>
                                      </div>

                                    </div>

                                    <div class="mt-3" v-if="isCaBankAccount">

                                      <div class="form-floating">
                                        <input type="number" class="form-control" id="bankAccountNumber" name="bankAccountNumber" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.number : ''" required>
                                        <label for="bankAccountNumber" class="form-label">Account Number</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="number" class="form-control" id="bankAccountInstitutionNumber" name="bankAccountInstitutionNumber" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.institutionNumber : ''" required>
                                        <label for="bankAccountInstitutionNumber" class="form-label">Institution Number</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="number" class="form-control" id="bankAccountBranchCode" name="bankAccountBranchCode" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.branchCode : ''" required>
                                        <label for="bankAccountBranchCode" class="form-label">Branch Code</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountBankName" name="bankAccountBankName" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bankName : ''" required>
                                        <label for="bankAccountBankName" class="form-label">Bank Name</label>
                                      </div>

                                    </div>

                                    <div class="mt-3" v-if="isGbBankAccount">

                                      <div class="form-floating">
                                        <input type="text" class="form-control" id="bankAccountNumber" name="bankAccountNumber" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.number : ''" required>
                                        <label for="bankAccountNumber" class="form-label">Account Number</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="number" class="form-control" id="bankAccountSortCode" name="bankAccountSortCode" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.sortCode : ''" required>
                                        <label for="bankAccountSortCode" class="form-label">Sort Code</label>
                                      </div>

                                    </div>

                                    <div class="mt-3" v-if="isOtherBankAccount">
                                      <div class="alert alert-warning" role="alert">
                                        <div class="text-dark my-auto">
                                          <font-awesome-icon :icon="['fas', 'circle-exclamation']" class="m-0 me-2"/>
                                          <span class="">Only use the 'OTHER' type if your account is registered in a country that doesn't use IBAN and isn't GB, US, or CA (for which you should use the dedicated type). We may require additional information depending on the country.</span>
                                        </div>
                                      </div>
                                      <div class="form-floating">
                                        <input type="text" class="form-control" id="bankAccountNumber" name="bankAccountNumber" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.accountNumber : ''" required>
                                        <label for="bankAccountNumber" class="form-label">Account Number</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountBic" name="bankAccountBic" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.bic : ''" required>
                                        <label for="bankAccountBic" class="form-label">BIC</label>
                                      </div>

                                      <div class="form-floating mt-3">
                                        <select class="form-select" id="bankAccountCountry" name="bankAccountCountry" required>
                                          <option disabled value="" :selected="formPrefilledBankAccountValues && !formPrefilledBankAccountValues.country"></option>
                                          <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="formPrefilledBankAccountValues ? isoCountry.code === formPrefilledBankAccountValues.country : ''" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                        </select>
                                        <label for="bankAccountCountry" class="form-label">Country</label>
                                      </div>

                                    </div>


                                  </div>

                                </div>

                                <!-- Owner information -->
                                <div>
                                  <hr class="mt-4">
                                  <h3 class="fw-bold mt-4 h4">Account owner</h3>
                                  <p class="">Please provide us with the exact name of the owner of the bank account.</p>

                                  <div class="row">

                                    <div class="col-12 col-lg-6">

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountOwnerName" name="bankAccountOwnerName" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.ownerName : ''" required>
                                        <label for="bankAccountOwnerName" class="form-label">Account owner full Name</label>
                                      </div>

                                    </div>

                                  </div>

                                  <hr>

                                  <div class="row">

                                    <div class="col-12">
                                      <h3 class="fw-bold mt-4 h4">Address</h3>
                                      <p class="">Please provide us with the address of the owner of the bank account.</p>

                                      <div class="alert alert-warning mt-3" role="alert">
                                        If your bank's branch is in another country then the country where the account owner is registered, please enter the bank's branch address in the form below.
                                      </div>
                                    </div>

                                  </div>

                                  <div class="row">

                                    <div class="col-12 col-lg-6">

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountAddressLine1" name="bankAccountAddressLine1" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.addressLine1 : ''" required>
                                        <label for="bankAccountAddressLine1" class="form-label">Address</label>
                                      </div>

                                    </div>

                                    <div class="col-12 col-lg-6">

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountPostalCode" name="bankAccountPostalCode" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.postalCode : ''" required>
                                        <label for="bankAccountPostalCode" class="form-label">Postal code</label>
                                      </div>

                                    </div>

                                    <div class="col-12 col-lg-6">

                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountCity" name="bankAccountCity" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.city : ''" required>
                                        <label for="bankAccountCity" class="form-label">City</label>
                                      </div>

                                    </div>

                                    <div class="col-12 col-lg-6">
                                      <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="bankAccountRegionOrCounty" name="bankAccountRegionOrCounty" placeholder="" :value="formPrefilledBankAccountValues ? formPrefilledBankAccountValues.regionOrCounty : ''">
                                        <label for="bankAccountRegionOrCounty" class="form-label">Region</label>
                                      </div>
                                    </div>

                                  </div>

                                  <div class="row">

                                    <div class="col-12 col-lg-6">
                                      <div class="form-floating mt-3">
                                        <select class="form-select" id="bankAccountCountry" name="bankAccountCountry" required>
                                          <option disabled value="" :selected="formPrefilledBankAccountValues && !formPrefilledBankAccountValues.country"></option>
                                          <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="formPrefilledBankAccountValues ? isoCountry.code === formPrefilledBankAccountValues.country : ''" :disabled="isoCountry.blocked==true">{{ isoCountry.name }}</option>
                                        </select>
                                        <label for="bankAccountCountry" class="form-label">Country</label>
                                      </div>
                                    </div>

                                  </div>

                                  <div class="row">
                                    <div class="col-12">
                                      <div class="w-100 mt-3">
                                        <button class="btn btn-lg btn-success w-100" role="button" type="button" @click="updateBankAccount()" >Save</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- ./Owner information -->
                              </form>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ./Bank account settings -->


                  </div>


                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
      <FooterSection />

      </div>

  </main>

</template>

<script>
import FooterSection from '@/components/public/Footer.vue'
import NavigationSection from "@/components/public/Navigation.vue";
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import { ref, watch, onMounted} from "vue";
import { useRoute } from 'vue-router';
import {
  mangopayOnboardingBankAccountItem,
  mangopayOnboardingItem,
  mangopayOnboardingError,
  getMangopayOnboardingBankAccount,
  addMangopayOnboardingBankAccount,
  getMangopayOnboardingStatus,
  updateMangopayOnboardingNaturalPerson,
  updateMangopayOnboardingLegalPerson,
} from "@/utils/mangopay.onboarding.public";
import {
  isLegalEntity,
  isEntityTypeBusiness,
  isEntityTypeOrganisation,
  isEntityTypeSoleProprietorship,
  getOnboardingStatus,
  onboardingStatus
} from "@/utils/onboarding";
import {getOtpUserId, otpUserId, getOtpUserProfile, otpUserProfile} from "@/utils/magiclink";
import {eftyIntegratorId} from "@/utils/environment.config";
import {isoCountriesList} from "@/utils/config";
import { isLoading } from "@/utils/support";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Tooltip } from 'bootstrap'

export default {

  name: 'PublicSettingsView',

  setup() {

    const route = useRoute();
    const activeTab = ref(route.params.tabId);
    const bankTab = ref(0)
    const accountTab = ref(0)
    const root = ref(0)
    const formPrefilledBankAccountValues = ref({})
    const formPrefilledProfileValues = ref({})
    const isIbanBankAccount = ref(0)
    const isUsBankAccount = ref(0)
    const isCaBankAccount = ref(0)
    const isGbBankAccount = ref(0)
    const isOtherBankAccount = ref(0)

    // Manage sub tabs via query parameters
    onMounted( () => {

      new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
      })

      if (activeTab.value) {

        if (activeTab.value == 'bank') {

          bankTab.value.click()

        } else if (activeTab.value == 'account') {

          accountTab.value.click()

        }

      }

    }),

    getOtpUserId()
    getOtpUserProfile()

    watch(otpUserId, () => {

      getMangopayOnboardingBankAccount(otpUserId.value, eftyIntegratorId).then( () => {
        prefillBankAccount()
      })

      getMangopayOnboardingStatus(otpUserId.value, eftyIntegratorId).then( () => {
        prefillProfileInfo()
      })

    });

    watch(otpUserProfile, () => {

      getOnboardingStatus(otpUserProfile.value)

    });


    watch(mangopayOnboardingBankAccountItem, () => {

      prefillBankAccount()

    });

    watch(mangopayOnboardingItem, () => {

      prefillProfileInfo()

    });

    const prefillProfileInfo = () => {
      // Only continue with a valid user profile
      if (otpUserId) {
        // Already in process of onboarding? Use info added to Mangopay to populate the form fields
        if (mangopayOnboardingItem.value) {
          // Natural person (non company)
          if (mangopayOnboardingItem.value.onboardingType == 'NATURAL_USER') {
            formPrefilledProfileValues.value = {
              'email': mangopayOnboardingItem.value.naturalUser.email,
              'firstName': mangopayOnboardingItem.value.naturalUser.firstName,
              'lastName': mangopayOnboardingItem.value.naturalUser.lastName,
              'countryOfResidence': mangopayOnboardingItem.value.naturalUser.countryOfResidence,
              'nationality': mangopayOnboardingItem.value.naturalUser.nationality,
              'onboardingType': mangopayOnboardingItem.value.onboardingType,
              'dateOfBirth': mangopayOnboardingItem.value.naturalUser.dateOfBirth
            }
          } else if (mangopayOnboardingItem.value.onboardingType == 'LEGAL_USER') {
            formPrefilledProfileValues.value = {
              'onboardingType': mangopayOnboardingItem.value.onboardingType,
              'registeredName': mangopayOnboardingItem.value.legalUser.registeredName,
              'companyNumber': mangopayOnboardingItem.value.legalUser.companyNumber,
              'companyVatNumber': mangopayOnboardingItem.value.legalUser.companyVatNumber,
              'email': mangopayOnboardingItem.value.legalUser.email,
              'addressLine1': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.addressLine1 : '',
              'city': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.city : '',
              'regionOrCounty': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.regionOrCounty : '',
              'postalCode': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.postalCode : '',
              'country': mangopayOnboardingItem.value.legalUser.registeredAddress ? mangopayOnboardingItem.value.legalUser.registeredAddress.country : '',
              'legalUserType': mangopayOnboardingItem.value.legalUser.legalUserType,
              'legalRepresentativeFirstName': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.firstName : '',
              'legalRepresentativeLastName': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.lastName : '',
              'legalRepresentativeEmail': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.email : '',
              'legalRepresentativeDateOfBirth': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.dateOfBirth : '',
              'legalRepresentativeNationality': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.nationality : '',
              'legalRepresentativeCountryOfResidence': mangopayOnboardingItem.value.legalUser.legalRepresentative ? mangopayOnboardingItem.value.legalUser.legalRepresentative.countryOfResidence : '',
            }

            // Push form into legal user mode
            isLegalEntity.value = true

            // Get available onboarding steps
            if (mangopayOnboardingItem.value.legalUser.legalUserType == 'BUSINESS') {
              isEntityTypeBusiness.value = true // Business
              isEntityTypeOrganisation.value = false // Organisation
              isEntityTypeSoleProprietorship.value = false // Sole proprietorship
            } else if (mangopayOnboardingItem.value.legalUser.legalUserType == 'ORGANIZATION') {
              isEntityTypeBusiness.value = false // Business
              isEntityTypeOrganisation.value = true // Organisation
              isEntityTypeSoleProprietorship.value = false // Sole proprietorship
            } else if (mangopayOnboardingItem.value.legalUser.legalUserType == 'SOLETRADER') {
              isEntityTypeBusiness.value = false // Business
              isEntityTypeOrganisation.value = false // Organisation
              isEntityTypeSoleProprietorship.value = true // Sole proprietorship
            }
          }

        }

      }
    }

    const prefillBankAccount = () => {
      
      // Get bank account details
      if (mangopayOnboardingBankAccountItem.value) {

        formPrefilledBankAccountValues.value = {
          ownerName: mangopayOnboardingBankAccountItem.value.ownerName,
          bankAccountType: mangopayOnboardingBankAccountItem.value.bankAccountType,
        }

        if (mangopayOnboardingBankAccountItem.value.ibanBankAccountDetails) {
          formPrefilledBankAccountValues.value.iban = mangopayOnboardingBankAccountItem.value.ibanBankAccountDetails.iban
          formPrefilledBankAccountValues.value.bic = mangopayOnboardingBankAccountItem.value.ibanBankAccountDetails.bic
        }

        if (mangopayOnboardingBankAccountItem.value.usBankAccountDetails) {
          formPrefilledBankAccountValues.value.accountNumber = mangopayOnboardingBankAccountItem.value.usBankAccountDetails.accountNumber
          formPrefilledBankAccountValues.value.aba = mangopayOnboardingBankAccountItem.value.usBankAccountDetails.aba
          formPrefilledBankAccountValues.value.depositType = mangopayOnboardingBankAccountItem.value.usBankAccountDetails.depositAccountType
        }

        if (mangopayOnboardingBankAccountItem.value.caBankAccountDetails) {
          formPrefilledBankAccountValues.value.accountNumber = mangopayOnboardingBankAccountItem.value.caBankAccountDetails.accountNumber
          formPrefilledBankAccountValues.value.institutionNumber = mangopayOnboardingBankAccountItem.value.caBankAccountDetails.institutionNumber
          formPrefilledBankAccountValues.value.branchCode = mangopayOnboardingBankAccountItem.value.caBankAccountDetails.branchCode
          formPrefilledBankAccountValues.value.bankName = mangopayOnboardingBankAccountItem.value.caBankAccountDetails.bankName
        }

        if (mangopayOnboardingBankAccountItem.value.gbBankAccountDetails) {
          formPrefilledBankAccountValues.value.accountNumber = mangopayOnboardingBankAccountItem.value.gbBankAccountDetails.accountNumber
          formPrefilledBankAccountValues.value.sortCode = mangopayOnboardingBankAccountItem.value.gbBankAccountDetails.sortCode
        }

        if (mangopayOnboardingBankAccountItem.value.otherBankAccountDetails) {
          formPrefilledBankAccountValues.value.accountNumber = mangopayOnboardingBankAccountItem.value.otherBankAccountDetails.accountNumber
          formPrefilledBankAccountValues.value.bic = mangopayOnboardingBankAccountItem.value.otherBankAccountDetails.bic
          formPrefilledBankAccountValues.value.country = mangopayOnboardingBankAccountItem.value.otherBankAccountDetails.country
        }

        if (mangopayOnboardingBankAccountItem.value.ownerAddress) {
          formPrefilledBankAccountValues.value.addressLine1 = mangopayOnboardingBankAccountItem.value.ownerAddress.addressLine1
          formPrefilledBankAccountValues.value.city = mangopayOnboardingBankAccountItem.value.ownerAddress.city
          formPrefilledBankAccountValues.value.regionOrCounty = mangopayOnboardingBankAccountItem.value.ownerAddress.regionOrCounty
          formPrefilledBankAccountValues.value.postalCode = mangopayOnboardingBankAccountItem.value.ownerAddress.postalCode
          formPrefilledBankAccountValues.value.country = mangopayOnboardingBankAccountItem.value.ownerAddress.country
        }

        isIbanBankAccount.value = false
        isUsBankAccount.value = false
        isCaBankAccount.value = false
        isGbBankAccount.value = false
        isOtherBankAccount.value = false

        if ( mangopayOnboardingBankAccountItem.value.bankAccountType == 'IBAN') {

          isIbanBankAccount.value = true

        } else if ( mangopayOnboardingBankAccountItem.value.bankAccountType == 'US') {

          isUsBankAccount.value = true

        } else if ( mangopayOnboardingBankAccountItem.value.bankAccountType == 'CA') {

          isCaBankAccount.value = true

        } else if ( mangopayOnboardingBankAccountItem.value.bankAccountType == 'GB') {

          isGbBankAccount.value = true

        } else if ( mangopayOnboardingBankAccountItem.value.bankAccountType == 'OTHER') {

          isOtherBankAccount.value = true

        }

      }
      
    }

    const onBankAccountChange = ( event ) => {
      isIbanBankAccount.value = false
      isUsBankAccount.value = false
      isCaBankAccount.value = false
      isGbBankAccount.value = false
      isOtherBankAccount.value = false

      if ( event.target.options[event.target.options.selectedIndex].value == '1') {

        isIbanBankAccount.value = true

      } else if ( event.target.options[event.target.options.selectedIndex].value == '2') {

        isUsBankAccount.value = true

      } else if ( event.target.options[event.target.options.selectedIndex].value == '3') {

        isCaBankAccount.value = true

      } else if ( event.target.options[event.target.options.selectedIndex].value == '4') {

        isGbBankAccount.value = true

      } else if ( event.target.options[event.target.options.selectedIndex].value == '5') {

        isOtherBankAccount.value = true

      }

    }

    const updateBankAccount = (  ) => {

      // Do HTML validation
      if (!root.value.querySelector('form#bankAccountForm').checkValidity()) {

        root.value.querySelector('form#bankAccountForm').reportValidity()

      }

      //Go to next step
      else {

        const bankAccountItem = ref({})
        const ownerAddress = ref({})

        // Address of bank account owner
        ownerAddress.value.addressLine1 = root.value.querySelector('[name="bankAccountAddressLine1"]').value
        ownerAddress.value.postalCode = root.value.querySelector('[name="bankAccountPostalCode"]').value
        ownerAddress.value.city = root.value.querySelector('[name="bankAccountCity"]').value
        ownerAddress.value.regionOrCounty = root.value.querySelector('[name="bankAccountRegionOrCounty"]').value
        ownerAddress.value.country = root.value.querySelector('[name="bankAccountCountry"]').value
        bankAccountItem.value.ownerAddress = ownerAddress.value

        // Basic fields
        bankAccountItem.value.ownerName = root.value.querySelector('[name="bankAccountOwnerName"]').value
        bankAccountItem.value.bankAccountType = root.value.querySelector('[name="bankAccountType"]').value

        // Bank account (diff buildup for each bank account type
        if (isIbanBankAccount.value) {

          const ibanBankAccountDetails = ref({})
          ibanBankAccountDetails.value.iban = root.value.querySelector('[name="bankAccountIban"]').value
          ibanBankAccountDetails.value.bic = root.value.querySelector('[name="bankAccountBic"]').value
          bankAccountItem.value.ibanBankAccountDetails = ibanBankAccountDetails.value

        } else if (isUsBankAccount.value) {

          const usBankAccountDetails = ref({})
          usBankAccountDetails.value.accountNumber = root.value.querySelector('[name="bankAccountNumber"]').value
          usBankAccountDetails.value.aba = root.value.querySelector('[name="bankAccountAba"]').value
          usBankAccountDetails.value.depositAccountType = root.value.querySelector('[name="bankAccountDepositType"]').value
          bankAccountItem.value.usBankAccountDetails = usBankAccountDetails.value

        } else if (isCaBankAccount.value) {

          const caBankAccountDetails = ref({})
          caBankAccountDetails.value.accountNumber = root.value.querySelector('[name="bankAccountNumber"]').value
          caBankAccountDetails.value.institutionNumber = root.value.querySelector('[name="bankAccountInstitutionNumber"]').value
          caBankAccountDetails.value.branchCode = root.value.querySelector('[name="bankAccountBranchCode"]').value
          caBankAccountDetails.value.bankName = root.value.querySelector('[name="bankAccountBankName"]').value
          bankAccountItem.value.caBankAccountDetails = caBankAccountDetails.value

        } else if (isGbBankAccount.value) {

          const gbBankAccountDetails = ref({})
          gbBankAccountDetails.value.accountNumber = root.value.querySelector('[name="bankAccountNumber"]').value
          gbBankAccountDetails.value.sortCode = root.value.querySelector('[name="bankAccountSortCode"]').value
          bankAccountItem.value.gbBankAccountDetails = gbBankAccountDetails.value

        } else if (isOtherBankAccount.value) {

          const otherBankAccountDetails = ref({})
          otherBankAccountDetails.value.accountNumber = root.value.querySelector('[name="bankAccountNumber"]').value
          otherBankAccountDetails.value.bic = root.value.querySelector('[name="bankAccountBic"]').value
          otherBankAccountDetails.value.country = root.value.querySelector('[name="bankAccountCountry"]').value
          bankAccountItem.value.otherBankAccountDetails = otherBankAccountDetails.value

        }

        // Pass to API Endpoint
        addMangopayOnboardingBankAccount(otpUserId.value, eftyIntegratorId, bankAccountItem.value).then( () => {

          Swal.fire({
            customClass: {
              closeButton: 'btn btn-lg btn-secondary',
              confirmButton: 'btn btn-lg btn-success order-last',
              denyButton: 'btn btn-lg btn-secondary',
              cancelButton: 'btn btn-lg btn-secondary'
            },
            toast: true,
            position: 'bottom-right',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: 'success',
            title: 'Changes saved'
          })

        })

      }

    }

    const updateProfile = (  ) => {

      // Do HTML validation
      if (!root.value.querySelector('form#profileDetailsForm').checkValidity()) {

        root.value.querySelector('form#profileDetailsForm').reportValidity()

      }

      //Go to next step
      else {

        // Get onboarding type from FORM
        const onboardingType = ref(0)
        onboardingType.value = root.value.querySelector('#onboardingType').value

        // Onboarding natural person
        if (onboardingType.value == 'NATURAL_USER') {

          // Fetch all data from FORM
          const firstName = ref(0)
          const lastName = ref(0)
          const dateOfBirth = ref(0)
          const nationality = ref(0)
          const countryOfResidence = ref(0)

          firstName.value = root.value.querySelector('#firstName').value
          lastName.value = root.value.querySelector('#lastName').value
          dateOfBirth.value = root.value.querySelector('#dateOfBirth').value
          nationality.value = root.value.querySelector('#nationality').value
          countryOfResidence.value = root.value.querySelector('#countryOfResidence').value

          updateMangopayOnboardingNaturalPerson(otpUserId.value, eftyIntegratorId, mangopayOnboardingItem, firstName.value, lastName.value, mangopayOnboardingItem.value.naturalUser.email, dateOfBirth.value, nationality.value, countryOfResidence.value).then( () => {
            Swal.fire({
              customClass: {
                closeButton: 'btn btn-lg btn-secondary',
                confirmButton: 'btn btn-lg btn-success order-last',
                denyButton: 'btn btn-lg btn-secondary',
                cancelButton: 'btn btn-lg btn-secondary'
              },
              toast: true,
              position: 'bottom-right',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: 'success',
              title: 'Changes saved'
            })
          })

        }

        // Onboarding legal person
        else if (onboardingType.value == 'LEGAL_USER') {

          // Fetch all data from FORM
          const legalUserType = ref(0)
          const registeredName = ref(0)
          const email = ref(0)
          const companyNumber = ref(0)
          const companyVatNumber = ref(0)
          const addressLine1 = ref(0)
          const postalCode = ref(0)
          const city = ref(0)
          const regionOrCounty = ref(0)
          const country = ref(0)
          const legalRepresentativeFirstName = ref(0)
          const legalRepresentativeLastName = ref(0)
          const legalRepresentativeEmail = ref(0)
          const legalRepresentativeDateOfBirth = ref(0)
          const legalRepresentativeNationality = ref(0)
          const legalRepresentativeCountryOfResidence = ref(0)

          // Company form fields
          legalUserType.value = root.value.querySelector('#legalUserType').value
          registeredName.value = root.value.querySelector('#registeredName').value
          email.value = root.value.querySelector('#email').value
          companyNumber.value = root.value.querySelector('#companyNumber').value
          companyVatNumber.value = root.value.querySelector('#companyVatNumber').value
          addressLine1.value = root.value.querySelector('#addressLine1').value
          postalCode.value = root.value.querySelector('#postalCode').value
          city.value = root.value.querySelector('#city').value
          regionOrCounty.value = root.value.querySelector('#regionOrCounty').value
          country.value = root.value.querySelector('#country').value

          // Legal rep form fields
          legalRepresentativeFirstName.value = root.value.querySelector('#legalRepresentativeFirstName').value
          legalRepresentativeLastName.value = root.value.querySelector('#legalRepresentativeLastName').value
          legalRepresentativeEmail.value = root.value.querySelector('#legalRepresentativeEmail').value
          legalRepresentativeDateOfBirth.value = root.value.querySelector('#legalRepresentativeDateOfBirth').value
          legalRepresentativeNationality.value = root.value.querySelector('#legalRepresentativeNationality').value
          legalRepresentativeCountryOfResidence.value = root.value.querySelector('#legalRepresentativeCountryOfResidence').value

          updateMangopayOnboardingLegalPerson(otpUserId.value, eftyIntegratorId, mangopayOnboardingItem, mangopayOnboardingItem.value.legalUser.uboDeclaration.beneficialOwners, legalUserType.value, registeredName.value, email.value, companyNumber.value, companyVatNumber.value, addressLine1.value, postalCode.value, city.value, regionOrCounty.value, country.value, legalRepresentativeFirstName.value, legalRepresentativeLastName.value, legalRepresentativeEmail.value, legalRepresentativeDateOfBirth.value, legalRepresentativeNationality.value, legalRepresentativeCountryOfResidence.value).then( () => {
            Swal.fire({
              customClass: {
                closeButton: 'btn btn-lg btn-secondary',
                confirmButton: 'btn btn-lg btn-success order-last',
                denyButton: 'btn btn-lg btn-secondary',
                cancelButton: 'btn btn-lg btn-secondary'
              },
              toast: true,
              position: 'bottom-right',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: 'success',
              title: 'Changes saved'
            })
          })

        }

      }

    }

    getMangopayOnboardingBankAccount()

    return {
      onBankAccountChange,
      updateBankAccount,
      updateProfile,
      formPrefilledBankAccountValues,
      formPrefilledProfileValues,
      root,
      isLoading,
      isIbanBankAccount,
      isUsBankAccount,
      isCaBankAccount,
      isGbBankAccount,
      isOtherBankAccount,
      isLegalEntity,
      isoCountriesList,
      dayjs,
      mangopayOnboardingError,
      onboardingStatus,
      bankTab,
      accountTab
    }

  },
  components: { LoaderSection, FooterSection, NavigationSection }
}
</script>
