<template>
  <footer class="fixed-xl-bottom py-4 w-100 bg-header h-auto mt-0 mt-lg-5">

    <div class="container-fluid">

      <div class="row pb-4">

        <div class="col-12 col-md-3 offset-md-3 col-xxl-2 offset-xxl-4 pb-5 pb-lg-0 text-white">

          <p class="fw-bold h4 text-white mb-3">About Efty</p>
          <a href="https://efty.com/about/" class="mb-3 text-white">About us</a><br>
          <a href="https://efty.com/contact/" class="mb-3 text-white">Contact</a><br>
          <a href="https://efty.com/legal/" class="mb-3 text-white">Legal</a><br>
          <a href="https://efty.com/support/" class="text-white">Support</a><br>
          <a href="https://efty.com/jobs/" class="text-white">Jobs</a><br>
          <a href="https://efty.com/sitemap/" class="text-white">Sitemap</a><br>

        </div>

        <div class="col-12 col-md-3 col-xxl-2 pb-3 pb-lg-0">

          <p class="fw-bold h4 text-white mb-3">Efty Pay</p>
          <a href="https://efty.com/products/efty_pay/" class="mb-2 text-white">Product features</a><br>
          <a href="https://efty.com/knowledge/buying-domains-made-easy/" class="mb-2 text-white">Buying domains explained</a><br>
          <a href="https://blog.efty.com/customer-support/" class="mb-2 text-white">Knowledge base</a><br>
          <a href="#" class="mb-2 text-white">API Documentation</a>

        </div>

      </div>

      <div class="row pb-3">

        <div class="col-12 col-md-3 offset-md-3 col-xxl-2 offset-xxl-4 pb-5 pb-lg-0 text-white">

          <a href="https://efty.com/"><img src="@/assets/logo-white.png" class="logo-footer img-responsive"></a>

        </div>

        <div class="col-12 col-md-3 col-xxl-2 pb-3 pb-lg-0">

          <ul class="social p-0 m-0">

            <li class="d-inline-block mr-2">
              <a href="https://twitter.com/eftycom" class="text-white h3"><font-awesome-icon :icon="['fab', 'twitter']" class="text-white me-2" /></a>
            </li>

            <li class="d-inline-block mr-2">
              <a href="mailto:ask@efty.com" class="text-white h3"><font-awesome-icon :icon="['far', 'envelope']" class="text-white me-2" /></a>
            </li>

          </ul>

        </div>


      </div>

      <div class="row">

        <div class="col-12">

          <hr class="bg-white border-white">

        </div>

      </div>

      <div class="row mt-2 text-center">

        <div class="col-12">

          <p class="text-white small mb-0">Copyright © 2023 - &copy; {{new Date().getFullYear()}} Efty Pay B.V. Groningen, The Netherlands. All rights reserved.</p>

        </div>

      </div>

    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterSection',
};
</script>